
export const removeUnderScore = (value: string) => {
    const modelName = value.split("_").join(" ")
    return modelName?.[0]?.toUpperCase() + modelName?.slice(1);
    // convert input "model_1" ==> "Model 1"
}


export const generateBase64 = async (data: any[]) => {
    const plotsWithGraph = await Promise.all(
        data?.map(async (plot: any, key: any) => {
            let svgDoms: any = document?.getElementsByClassName(
                "highcharts-root" || ""
            )
            svgDoms[key]?.setAttribute("id", "plot" + plot?.chart?.id)
            const tempElement = document.createElement("div");
            tempElement.innerHTML = plot.caption.text;
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")
            const svgXml = new XMLSerializer()?.serializeToString(svgDoms[key])
            canvas.width = svgDoms[key]?.width?.baseVal?.value
            canvas.height = svgDoms[key]?.height?.baseVal?.value
            const img = document.createElement("img")
            img.setAttribute(
                "src",
                "data:image/svg+xml;base64," +
                window.btoa(unescape(encodeURIComponent(svgXml)))
            )
            try {
                await img.decode()
                ctx?.drawImage(
                    img,
                    0,
                    0,
                    svgDoms[key]?.width?.baseVal?.value,
                    svgDoms[key]?.height?.baseVal?.value
                )
                if (ctx) {
                    const marginTop = 50;
                    const marginRight = 20;
                    var fontArgs = ctx.font.split(' ');
                    ctx.font = `14px  ${fontArgs?.[fontArgs?.length - 1]}`
                    ctx.translate(canvas.width - svgDoms[key].width.baseVal.value - marginRight, marginTop);
                    ctx.textAlign = "right";
                    ctx.textBaseline = "top";
                }
                ctx?.fillText(tempElement.innerText, canvas.width, 0);
                return Promise.resolve(canvas.toDataURL("image/png", 1.0))
            } catch (error) {
                return Promise.reject()
            }
        })
    )
    return plotsWithGraph
}