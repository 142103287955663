import { HolderOutlined } from "@ant-design/icons";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useSortable, arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { TransferProps, Button, RowProps, Tag, Transfer, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { TransferItem } from "antd/es/transfer";
import { difference } from "lodash";
import React, { useContext, useMemo } from "react";
import { CSS } from '@dnd-kit/utilities'
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { StoreState } from "src/store/configureStore";
import { useSelector } from "react-redux";


interface TableTransferProps extends TransferProps<TransferItem> {
    onSort: (targetKeys: string[]) => void
    unsortedTargetKeys: string[]
    allParameterData: any[]
    sortOrders: any
    changeSortOrders: any
    filters: any
    toggleFilters: any
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

const TableRow: React.FC<RowProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: (props as any)['data-row-key'] });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

export const TableTransfer = ({ allParameterData, onSort, sortOrders, changeSortOrders, unsortedTargetKeys, targetKeys, filters, toggleFilters, ...restProps }: TableTransferProps) => {

    const { projectList } = useSelector((state: StoreState) => state.projects)

    const uniqueCategories = useMemo(() => Array.from(new Set(allParameterData.map((data: any) => data.category))).map(category => ({ text: category, value: category })), [allParameterData])

    const leftColumns: ColumnsType<any> = useMemo(() => [
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Name',
            sorter: {
                compare: (a: any, b: any) => {
                    return a.title?.trim().localeCompare(b.title?.trim())
                },
                multiple: 1
            },
        },
        {
            key: 'category',
            dataIndex: 'category',
            title: 'Category',
            filters: uniqueCategories,
            filterSearch: true,
            onFilter: (value, record) => record.category?.startsWith(value as string),
            sorter: {
                compare: (a: any, b: any) => {
                    return String(a.category || "").toLowerCase().trim()?.localeCompare(String(b.category || "").toLowerCase().trim())
                },
                multiple: 1
            },
            render: category => <Tag>{category}</Tag>,
        },
        {
            dataIndex: 'projects',
            title: 'Projects',
            render: projects => projects?.map((projectId: string) => projectList.find(project => project.project_id === projectId)?.name).join(", "),
            filters: projectList.map(project => ({ text: project.name, value: project.project_id })),
            filterSearch: true,
            onFilter: (value, record) => record.projects?.includes(value as string),
        },
    ], [projectList, uniqueCategories]);

    const rightColumns: ColumnsType<any> = useMemo(() => [
        { key: 'sort', title: '#', align: 'center', width: 40, render: () => <DragHandle /> },
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Name',
            sorter: { multiple: 1 },
            sortOrder: sortOrders["title"]
        },
        {
            key: 'category',
            dataIndex: 'category',
            title: 'Category',
            filters: uniqueCategories,
            filterSearch: true,
            onFilter: (value, record) => record.category?.startsWith(value as string),
            sorter: {
                compare: (a: any, b: any) => {
                    return String(a.category || "").toLowerCase().trim()?.localeCompare(String(b.category || "").toLowerCase().trim())
                },
                multiple: 1
            },
            render: category => <Tag>{category}</Tag>,
            sortOrder: sortOrders["category"],
            filteredValue: filters["category"] || null
        },
    ], [filters, sortOrders, uniqueCategories])


    return (
        <Transfer
            {...restProps}
            targetKeys={targetKeys}
            dataSource={allParameterData}
            showSelectAll={false}
        >
            {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;

                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter(item => !item.disabled)
                            .map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, listSelectedKeys)
                            : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys as string[], selected);
                    },
                    onSelect({ key }, selected) {
                        onItemSelect(key as string, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                const onDragEnd = ({ active, over }: DragEndEvent) => {
                    if (targetKeys && active.id !== over?.id) {
                        const activeIndex = targetKeys.findIndex((key) => key === active?.id);
                        const overIndex = targetKeys.findIndex((key) => key === over?.id);
                        const newState = arrayMove(targetKeys, activeIndex, overIndex);
                        onSort(newState)
                    }
                };

                return (
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext items={filteredItems.map((i) => i.key) as any[]} strategy={verticalListSortingStrategy}>
                            <Table
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={filteredItems}
                                size="small"
                                style={{ pointerEvents: listDisabled ? 'none' : undefined, margin: 8 }}
                                onRow={({ key, disabled: itemDisabled }) => ({
                                    onClick: () => {
                                        if (itemDisabled || listDisabled) return;
                                        onItemSelect(key as string, !listSelectedKeys.includes(key as string));
                                    },
                                })}
                                components={{ body: { row: TableRow } }}
                                scroll={{ y: "40vh" }}
                                {...(direction === "right" && { pagination: false })}
                                onChange={(p, f, s: any, e) => {
                                    if (direction === 'right') {

                                        if (e.action === "filter") {
                                            toggleFilters(f)
                                        }

                                        if (e.action === "sort") {
                                            let sortOpts = Array.isArray(s) ? s : [s]

                                            let newSortOrders = {}
                                            sortOpts.forEach((sortOpt) => {
                                                newSortOrders = { ...newSortOrders, [sortOpt.columnKey]: sortOpt.order }
                                            })
                                            changeSortOrders(newSortOrders)

                                            let newList: string[] = []
                                            if (sortOpts.some((sortOpt: any) => sortOpt.order)) {
                                                newList = unsortedTargetKeys.map(key => e.currentDataSource.find(item => item.key === key)).toSorted((a: any, b: any) => {
                                                    let flag = 0
                                                    sortOpts.forEach(sortOpt => {

                                                        if (sortOpt.order === 'ascend') {
                                                            if (!flag) {
                                                                flag = a[sortOpt.columnKey]?.trim().localeCompare(b[sortOpt.columnKey]?.trim())
                                                            }
                                                        } else if (sortOpt.order === 'descend') {
                                                            if (!flag) {
                                                                flag = b[sortOpt.columnKey]?.trim().localeCompare(a[sortOpt.columnKey]?.trim())
                                                            }
                                                        }

                                                    })

                                                    return flag
                                                })
                                                    .map(item => item?.key as any)

                                            } else {
                                                newList = unsortedTargetKeys
                                                changeSortOrders({})
                                            }

                                            onSort(newList)
                                        }
                                    }
                                }}
                            />
                        </SortableContext>
                    </DndContext>
                );
            }}
        </Transfer>
    )
};