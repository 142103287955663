import { SettingOutlined } from "@ant-design/icons";
import { Modal, Popconfirm } from "antd";
import { useState, useEffect } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import { ModelConfig } from "src/typings";
import { TableTransfer } from "./TableTransfer";
import useTranslate from "src/utils/useTranslate";

interface ModifyProps {
  parameterList: string[];
  allParameterData: any[];
  handleModify: any;
  aiEngineVersionData?: ModelConfig | undefined;
  linkedTrialIdDisplayName?: { [key: string]: string }
  titles: string[]
}

export const Modify = ({ parameterList, allParameterData, handleModify, titles }: ModifyProps) => {
  const [t] = useTranslate()

  const [targetKeys, setTargetKeys] = useState<string[]>(parameterList);
  useEffect(() => { setTargetKeys(parameterList.filter(id => !id.includes(t("workOrderDetails.newParameter")))) }, [parameterList, t])

  const [showModify, setShowModify] = useState(false)

  const [loading, setLoading] = useState(false)
  const [unsortedTargetKeys, setUnsortedTargetKeys] = useState(parameterList)
  const [filters, setFilters] = useState({})
  const [sortOrders, setSortOrders] = useState({})
  useEffect(() => { setUnsortedTargetKeys(parameterList.filter(id => !id.includes(t("workOrderDetails.newParameter")))) }, [parameterList, t])

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
    setUnsortedTargetKeys(nextTargetKeys);
    setSortOrders({})
  };

  const onSort = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
  };

  const changeSortOrders = (sortOrders: {}) => {
    setSortOrders(sortOrders)
  };

  const toggleFilters = (filters: any) => {
    setFilters(filters)
  };

  const onConfirm = async () => {
    setLoading(true);
    setTimeout(() => {
      handleModify(targetKeys)
      setShowModify(false)
      setLoading(false)
    }, 300);
  }

  const onReset = () => {
    setTargetKeys(parameterList)
    changeSortOrders({})
    toggleFilters({})
  }

  return (<>
    <StyledButton
      size='small'
      onClick={() => { setShowModify(state => !state) }}
      icon={<SettingOutlined />}
    >
      {"Modify"}
    </StyledButton>
    <Modal
      width={"95%"}
      styles={{ body: { padding: 8 } }}
      style={{ top: 32 }}
      open={showModify}
      onCancel={() => { setShowModify(false); onReset() }}
      title={`Modify Work Order`}
      footer={[
        <StyledButton key="back" onClick={onReset}>
          Reset
        </StyledButton>,
        <Popconfirm
          title="Are you sure to modify this work order?"
          onConfirm={onConfirm}
          okText="Confirm"
          showCancel={false}
        >
          <StyledButton key="submit" type="primary" loading={loading}>
            Modify
          </StyledButton>
        </Popconfirm>
        ,
      ]}
      destroyOnClose
    >
      <TableTransfer
        unsortedTargetKeys={unsortedTargetKeys}
        targetKeys={targetKeys}
        showSearch={true}
        onChange={onChange}
        onSort={onSort}
        filterOption={(inputValue, item) => (item.title && item.title!.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1) || (item.category && item.category?.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1)}        
        allParameterData={allParameterData}
        sortOrders={sortOrders}
        changeSortOrders={changeSortOrders}
        titles={titles}
        filters={filters}
        toggleFilters={toggleFilters}
      />
    </Modal>
  </>)
}